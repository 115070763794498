import React, { ReactNode, useEffect, useMemo } from 'react';
import { Sidebar } from '../Sidebar';
import RoutingPath from '../../routing/RoutingPath';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading/Loading';
import { MobileNavBar } from '../navigation/mobile/mobile-nav-bar';

interface PageProps {
  children: ReactNode;
}

export default function Page({ children }: PageProps) {
  const {
    isAuthenticated,
    user,
    isLoading,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    getAccessTokenWithPopup,
  } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  // Read the URL from the browser
  const currentUrl = window.location.pathname;

  const queryParams = new URLSearchParams(location.search);

  console.log('queryParams');
  console.log(queryParams);

  // Define the desired location to redirect
  const desiredLocation = '/payment-success';

  // Redirect to the desired location if the current URL matches a certain condition
  useEffect(() => {
    // const fetchToken = async () => {
    //   const token = await getAccessTokenSilently();
    //   console.log('PAGE token: ', token);
    // };
    // fetchToken();
    if (
      currentUrl === RoutingPath.PAYMENT_SUCCESS ||
      location.pathname === RoutingPath.PAYMENT_SUCCESS
    ) {
      console.log('Navigate to: /payment-success');
      navigate(RoutingPath.PAYMENT_SUCCESS);
    } else if (
      currentUrl === RoutingPath.PAYMENT_FAILED ||
      location.pathname === RoutingPath.PAYMENT_FAILED
    ) {
      console.log('Navigate to: /payment-failed');
      navigate(RoutingPath.PAYMENT_FAILED);
    }
  }, []);

  // const isNotAuthenticated = !isAuthenticated && !userId;
  // const { data, error, isLoading } = useQuery({
  //   queryKey: ['user'],
  //   queryFn: async () => {
  //     try {
  //       console.log('AUTH check');
  //       const id = localStorage.getItem('userId');
  //       if (!id) throw new Error('No user id');
  //       const user = await new UserApi().get(id);
  //       setUserId(user._id);
  //       setIsAuthenticated(true);
  //     } catch (error) {
  //       console.error('Error AUTH check', error);
  //       setUserId('');
  //       setIsAuthenticated(false);
  //       throw error;
  //     }
  //   },
  //   retry: false,
  //   enabled: setIsAuthenticated
  // });

  const selected: string | undefined = useMemo(() => {
    if (currentPath === RoutingPath.CREATE || currentPath === '/') {
      return RoutingPath.CREATE;
    } else if (currentPath === RoutingPath.ACCOUNT) {
      return RoutingPath.ACCOUNT;
    } else if (currentPath === RoutingPath.SAVED) {
      return RoutingPath.SAVED;
    } else if (currentPath === RoutingPath.PATIENTS) {
      return RoutingPath.PATIENTS;
    } else if (currentPath === RoutingPath.TRANSCRIPTION) {
      return RoutingPath.TRANSCRIPTION;
    } else if (currentPath === RoutingPath.SUPPORT) {
      return RoutingPath.SUPPORT;
    } else if (currentPath === RoutingPath.PRIVACY) {
      return RoutingPath.PRIVACY;
    } else if (currentPath === RoutingPath.PAYMENT) {
      return RoutingPath.PAYMENT;
    }
    return currentPath;
  }, [location.pathname]);

  const isLoggedIn = useMemo(() => {
    return isAuthenticated;
  }, [isAuthenticated]);

  const errorDescription = queryParams.get('error_description');

  console.log('errorDescription', errorDescription);

  if (errorDescription === 'NOT_VERIFIED_EMAIL_ERROR') {
    return <Navigate to={RoutingPath.VERIFY_EMAIL} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={RoutingPath.LOGIN} />;
  }

  const loadingComponent = <Loading />;

  return (
    <div className="block">
      <div className="bg-background relative">
        <MobileNavBar>
          <Sidebar selected={selected} className="h-lvh block" />
        </MobileNavBar>
        <div className="main-page-content grid md:grid-cols-4 lg:grid-cols-5">
          <Sidebar selected={selected} className="hidden h-lvh md:block" />
          <div className="col-span-3 md:border-l lg:col-span-4">
            <div className="h-full px-4 py-6 lg:px-8">
              {isLoading ? loadingComponent : children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
