import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Table } from '../ui/Table/Table';
import { TableHeader } from '../ui/Table/TableHeader';
import { TableRow } from '../ui/Table/TableRow';
import { TableHead } from '../ui/Table/TableHead';
import { TableBody } from '../ui/Table/TableBody';
import { TableCell } from '../ui/Table/TableCell';
import { Button } from '../ui/Button';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { DeepgramFullResult, DeepgramResponse } from './RenderNote';
import ResultApi from '../../api/ResultApi';
import Loading from '../Loading/Loading';
import FindAllResultsResponse from '../../result/FindAllResultsResponse';
import { useCallback, useMemo, useState } from 'react';
import { InputWithIcon } from '../ReusableElements/InputWithIcon';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '../ui/Toast/UseToast';

interface PatientRow {
  _id: string;
  name: string;
}

const columns: ColumnDef<DeepgramResponse>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      return <div>{row.getValue('name') || ''}</div>;
    },
  },
  {
    accessorKey: 'patientName',
    header: 'Patient',
    cell: ({ row }) => {
      const patientName = row.getValue('patientName') as string;
      return <div>{patientName || '-'}</div>;
    },
  },
  {
    accessorKey: 'updatedAt',
    header: 'Last Updated',
    cell: ({ row }) => {
      const updatedAt = row.getValue('updatedAt') as string;
      if (updatedAt) {
        const formattedDate = format(
          new Date(updatedAt),
          'dd-MM-yyyy HH:mm:ss'
        );
        return <div>{formattedDate}</div>;
      }
      return <div>{'-'}</div>;
    },
  },
];

export default function SoapNotesTable() {
  const { userId } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const [searchInput, setSearchInput] = useState('');
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, error, isLoading, refetch } = useQuery<
    FindAllResultsResponse | undefined,
    Error
  >({
    queryKey: ['results', pageIndex, query],
    queryFn: async () => {
      try {
        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        return new ResultApi().getAll(user.sub, token, pageIndex + 1, query);
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Could not find patients',
          description: 'Please try again in few moments or contact support',
        });
        console.log(e);
      }
    },
    retry: false,
    cacheTime: 0,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data?.results || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    state: {
      rowSelection,
      pagination,
    },
    manualPagination: true,
    pageCount: data?.pages,
  });

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      setQuery(searchQuery);
    }, 500),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchInput(value);
    debouncedSearch(value);
  };

  const handleResultNavigate = (note: DeepgramResponse) => {
    navigate(`/soap-note/${note._id}`, { state: note });
  };

  return (
    <div className="size-full">
      <h2 className="mb-5 text-lg font-bold">SOAP Notes</h2>
      <div>
        <InputWithIcon
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search"
          Icon={Search}
        />
      </div>
      {isLoading ? <Loading /> : null}
      {!isLoading && error ? <div>Error: {error.message}</div> : null}
      {!isLoading && !error ? (
        <>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          className="text-sm font-semibold text-black "
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      className="cursor-pointer"
                      onClick={() =>
                        handleResultNavigate(row.original as DeepgramResponse)
                      }
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-end space-x-2 p-4">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}
