import React, { useState } from 'react';
import { Select } from './ui/Select/Select';
import { SelectTrigger } from './ui/Select/SelectTrigger';
import { SelectValue } from './ui/Select/SelectValue';
import { SelectContent } from './ui/Select/SelectContent';
import { SelectItem } from './ui/Select/SelectItem';
import { SelectGroup } from './ui/Select/SelectGroup';
import { Card } from './ui/Card/Card';
import { CardHeader } from './ui/Card/CardHeader';
import { CardTitle } from './ui/Card/CardTitle';
import { CardDescription } from './ui/Card/CardDescription';
import { useTranslation } from 'react-i18next';
import { Button } from './ui/Button';
import { CardContent } from './ui/Card/CardContent';
import { CardFooter } from './ui/Card/CardFooter';
import UserApi from '../api/UserApi';
import { Language } from '../user/UserData';
import { useAuth0 } from '@auth0/auth0-react';
import { SelectFieldOption } from './Note/NotePatient';
import { useToast } from './ui/Toast/UseToast';
import { useUserData } from '../context/UserContextProvider';

const TranscriptionSettings = () => {
  const { userData, setUserData } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { toast } = useToast();
  const [selectedLang, setSelectedLang] = useState(userData?.lang || 'en');

  const LANGUAGES: SelectFieldOption[] = [
    {
      name: 'English',
      value: 'en',
    },
    {
      name: 'Spanish',
      value: 'es',
    },
  ];

  const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (!selectedLang) {
        throw new Error('Invalid data');
      }
      if (!user?.sub || !user?.email) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      const response = await new UserApi().updateLanguage(
        user.sub,
        selectedLang,
        token
      );
      console.log('language response', response);
      if (userData) {
        setUserData({ ...userData, lang: selectedLang });
      }
      toast({
        title: 'Success',
        description: 'Language saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.error(e);
    }
  };

  return (
    <div className={`flex justify-center`}>
      <Card className="mx-auto max-w-[500px] w-full">
        <CardHeader>
          <CardTitle className="text-lg font-bold">{t('note.lang')}</CardTitle>
          <CardDescription>{t('note.langInfo')} </CardDescription>
        </CardHeader>
        <div>
          <CardContent className=" flex w-full items-center">
            <Select
              value={selectedLang}
              onValueChange={(val) => setSelectedLang(val as Language)}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {LANGUAGES.map(({ name, value }, index) => (
                    <SelectItem key={index} value={value}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </CardContent>
          <CardFooter>
            <Button onClick={handleUpdate} className="w-full">
              {t('note.submit')}
            </Button>
          </CardFooter>
        </div>
      </Card>
    </div>
  );
};

export default TranscriptionSettings;
